import { initFlowbite } from "flowbite";
import FormButton from "../components/buttons/FormButton";
import Footer from "../components/footer/Footer";
import File from "../components/inputs/File";
import FloatingInput from "../components/inputs/FloatingInput";
import Textarea from "../components/inputs/Textarea";
import MainNav from "../components/navbar/MainNav";
import { useEffect, useRef, useState } from "react";

export default function() {
    const [helpForm, setHelpForm] = useState({email: "", subject: "", text: ""});
    const [jobForm, setJobForm] = useState({mail: '', pos: '', CV: ''})
    setTimeout(() => {
        initFlowbite()
    }, 1000)

    function handleHelpFormChange(e) {
        e.preventDefault()
        const {id, value} = e.target
        setHelpForm(prev => {
            return {
                ...prev, 
                [id]: value
            }
        })
    }

    function handleJobFormChange(e) {
        e.preventDefault()
        const id = e.target.id;
        const value = e.target.type == 'file' ? e.target.files[0] : e.target.value
        setJobForm(prev => {
            return {
                ...prev,
                [id]: value
            }
        })
    }

    function sendHelpForm(e) {
        e.preventDefault()

        if (window.Email) {
            window.Email.send({
                SecureToken: 'b48c5110-dac8-4970-9d20-1a83df6d13d6',
                To: 'deanmaja6@gmail.com', 
                From: 'deanmaja6@gmail.com',
                Subject: helpForm.subject,
                Body: `Message from ${helpForm.email}: ${helpForm.text}`
            }).then(res => console.log(res))
            .catch(err => console.log(err))
        }
    }

    function sendJobForm(e) {
        e.preventDefault()

        if (window.Email) {
            window.Email.send({
                SecureToken: 'b48c5110-dac8-4970-9d20-1a83df6d13d6',
                To: 'deanmaja6@gmail.com', 
                From: 'deanmaja6@gmail.com',
                Subject: 'Job application',
                Body: `Message from ${jobForm.mail}:`,
            }).then(res => console.log(res))
            .catch(err => console.log(err))
        }
    }

    useEffect(() => {
        console.log(jobForm.CV)
    }, [jobForm.CV])

    return (
        <div className="h-screen">
            <MainNav />
            <div className="auth-height flex items-center contact-dir">
                <div className="w-1/2 p-6 contact-width">
                    <div className="border-2 border-black grid grid-cols-1 place-items-center gap-y-2 p-12">
                        <h1 className="text-center text-2xl font-bold">Product help</h1>
                        <form className="w-full grid grid-cols-1 place-items-center space-y-2" onSubmit={sendHelpForm}>
                            <FloatingInput id="email" value={helpForm.email} handleChange={handleHelpFormChange} type="email" label="Your email" placeholder="Email"/>
                            <FloatingInput id="subject" value={helpForm.subject} handleChange={handleHelpFormChange} type="text" label="Subject" placeholder="Product code"/>
                            <Textarea id="text" value={helpForm.text} handleChange={handleHelpFormChange} placeholder="Enter message..." />
                            <FormButton button="Send"/>
                        </form>
                    </div>
                </div>
                <div className="w-1/2 p-6 contact-width">
                    <div className="border-2 border-black grid grid-cols-1 place-items-center gap-y-2 p-12">
                        <h1 className="text-center text-2xl font-bold">Job application</h1>
                        <form className="w-full grid grid-cols-1 place-items-center space-y-2" onSubmit={sendJobForm}>
                            <FloatingInput id="mail" value={jobForm.mail} handleChange={handleJobFormChange} type="email" label="Your email" placeholder="Email" />
                            <FloatingInput id="pos" value={jobForm.pos} handleChange={handleJobFormChange} type="text" label="Job position" placeholder="Product code" />
                            <File label="Upload CV" accept=".docx, .pdf" id='CV' handleChange={handleJobFormChange} />
                            <FormButton button="Send"/>
                        </form>
                    </div>
                </div>
            </div>
    
            <Footer />
        </div>
    )
}