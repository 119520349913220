import { useContext, useEffect, useState } from "react";
import NavLink from "../navbar/NavLink";
import Socials from "../socials/Socials";
import { UserContext } from "../../contexts/AuthContext";
import SignOut from "../buttons/SignOut";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { ShoppingContext } from "../../contexts/CategoryToShop";
import { useNavigate } from "react-router-dom";

export default function() {
    const navigate = useNavigate()
    const user = useContext(UserContext)
    const category = useContext(ShoppingContext)
    const [categories, setCategories] = useState([])

    function handleClick(item) {
        category.dispatch(item)
        navigate("/shop")
    }

    useEffect(() => {
        const unsub = onSnapshot(collection(db, "categories"), res => {
            const array = []
            res.forEach(data => {
                array.push(data.data())
            })
            setCategories(array)
        })
        return () => {
            unsub()
        }
    }, [])
    return (
        <div className="bg-slate-200 h-fit">
            <div className="flex flex-wrap justify-evenly items-start py-2 ">
                <div className="max-w-96">
                    <p className="text-base font-medium text-center">Explore all variety of products for a reasonable price. Our staff is always available for you to help.</p>
                    <Socials />
                </div>
                <div className="list-none flex flex-col flex-dir mt-2">
                    <NavLink name="Home" path="/" />
                    <NavLink name="About" path="/about" />
                    <NavLink name="Contact" path="/contact" />
                </div>
                <div>
                    <p className="text-base font-medium text-center mt-2 mb-0">Shop</p>
                    <div className="list-none flex flex-wrap justify-center">
                        {categories.map(item => {
                            return <a href="#" onClick={() => handleClick(item)} className="block p-2 sm:py-0.5 rounded hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{item.name}</a>
                        })}
                    </div>
                </div>
                <div>
                    <p className="text-base font-medium text-center mt-2 mb-0">Contact</p>
                    <div className="list-none flex flex-wrap justify-center">
                        <NavLink name="About us" path="/about"/>
                        <NavLink name="Product support" path="/contact"/>
                        <NavLink name="Looking for a job?" path="/contact"/>
                    </div>
                </div>
                <div className="list-none flex flex-col items-center">
                    {user && <div className="flex justify-center items-center">
                        <img src={user.photoURL} className="w-12 h-12 rounded-[50%] mt-1"/>
                        <span className="text-base font-medium ms-1">{user.displayName}</span>
                    </div>}
                    {user ? <SignOut /> : <NavLink name="Login" path="/login" />}
                </div>
            </div>
            <div className="grid grid-cols-1 place-items-center">
                <div className="w-[95%] h-0.5 bg-slate-100"></div>
            </div>
            <p className="mt-1 ps-12 font-normal text-base">Copyright© 1995-2024 SAMSUNG All Rights Reserved.</p>
        </div>
    )
}